// 目前支持的主题； 内部定义两个常量，应对crm那边值变更的情况，只需改这里的配置，应用代码依赖常量无需改动
import { getParameterByName } from './qs';
const THEME_ORANGE = "orange";
const THEME_BLUE = "blue";
const DEFAULT_THEME = getParameterByName('brand') === "ik" ? THEME_BLUE : THEME_ORANGE; //  默认蓝色主题 逻辑有误 修改为从url品牌获取
// window.theme_style = 'orange'

const themeConfig = {
  [THEME_ORANGE]: {
    primaryColor: "#FC8352",
    linkColor: "#FC8352",
  },
  [THEME_BLUE]: {
    primaryColor: window.platform_1688 ? '#0077FF' : "#108EE9",
    linkColor:  window.platform_1688 ? '#0077FF' : "#108EE9",
  },
};

// 获取主题
function getTheme() {
  if (window.platform_1688) return THEME_BLUE;
  if (!window.theme_style) return DEFAULT_THEME;
  if (Object.keys(themeConfig).some((color) => color === window.theme_style)) {
    return window.theme_style;
  }
  return DEFAULT_THEME;
}

// 获取主题配置
function getThemeConfig() {
  if (!window.theme_style) return themeConfig[DEFAULT_THEME];

  return themeConfig[window.theme_style] || themeConfig[DEFAULT_THEME];
}

function getThemeColor() {
  return getThemeConfig().primaryColor;
}

// 根据brand动态引入主题style
export function requireTheme(brand) {
  switch (brand) {
    case "ik":
      require("../styles/blueTheme.less");
      break;
    default:
      require("../styles/orangeTheme.less");
      break;
  }
}

export default {
  getTheme,
  getThemeConfig,
  getThemeColor,
  THEME_ORANGE,
  THEME_BLUE,
};
